// JsFromRoutes CacheKey 37eda0f07bfcdd66b3b46999d16324e2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  reenable: /* #__PURE__ */ definePathHelper('patch', '/wallets/:id/reenable'),
  index: /* #__PURE__ */ definePathHelper('get', '/wallets'),
  create: /* #__PURE__ */ definePathHelper('post', '/wallets'),
  update: /* #__PURE__ */ definePathHelper('patch', '/wallets/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/wallets/:id'),
}
