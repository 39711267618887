// JsFromRoutes CacheKey aad2aef8d9e16ddabb2423ba54c9a112
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  updateAll: /* #__PURE__ */ definePathHelper('patch', '/transactions/update_all'),
  index: /* #__PURE__ */ definePathHelper('get', '/transactions'),
  create: /* #__PURE__ */ definePathHelper('post', '/transactions'),
  update: /* #__PURE__ */ definePathHelper('patch', '/transactions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/transactions/:id'),
}
