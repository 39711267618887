// JsFromRoutes CacheKey 15b2099939dbbbaa72e3eb69347d55da
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/auth/verification/new'),
  show: /* #__PURE__ */ definePathHelper('get', '/auth/verification'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth/verification'),
}
