// JsFromRoutes CacheKey 48bf1e4d728ef0cf9118a1942846d64c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/auth/login'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth/login'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/auth/logout'),
}
