// JsFromRoutes CacheKey 5480684838ce2a65c8ed14e0cfd17ee8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/imports'),
  create: /* #__PURE__ */ definePathHelper('post', '/imports'),
  show: /* #__PURE__ */ definePathHelper('get', '/imports/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/imports/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/imports/:id'),
}
