// JsFromRoutes CacheKey d01dfb3e229d1f3be33e27c41fec9134
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/profile_share_invites_received'),
  update: /* #__PURE__ */ definePathHelper('patch', '/profile_share_invites_received/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/profile_share_invites_received/:id'),
}
