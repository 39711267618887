// JsFromRoutes CacheKey 057cf44ef61c5dc66400084ab217d984
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  disable: /* #__PURE__ */ definePathHelper('delete', '/transaction_automations/:id/disable'),
  reenable: /* #__PURE__ */ definePathHelper('patch', '/transaction_automations/:id/reenable'),
  index: /* #__PURE__ */ definePathHelper('get', '/transaction_automations'),
  create: /* #__PURE__ */ definePathHelper('post', '/transaction_automations'),
  show: /* #__PURE__ */ definePathHelper('get', '/transaction_automations/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/transaction_automations/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/transaction_automations/:id'),
}
