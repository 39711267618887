// JsFromRoutes CacheKey 47f13bb6d586059651dbf44df5b74bcd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  disable: /* #__PURE__ */ definePathHelper('delete', '/transaction_predictions/:id/disable'),
  reenable: /* #__PURE__ */ definePathHelper('patch', '/transaction_predictions/:id/reenable'),
  index: /* #__PURE__ */ definePathHelper('get', '/transaction_predictions'),
  create: /* #__PURE__ */ definePathHelper('post', '/transaction_predictions'),
  new: /* #__PURE__ */ definePathHelper('get', '/transaction_predictions/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/transaction_predictions/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/transaction_predictions/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/transaction_predictions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/transaction_predictions/:id'),
}
